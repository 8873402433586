import {
  entries,
  findKey,
  get,
  find,
  isPlainObject,
  map,
  omit,
  some,
  snakeCase,
  isArray,
  camelCase,
} from 'lodash'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { AppliedFilters } from 'components/AppliedFilters'
import { Button, BUTTON_VARIANT } from 'components/Button'
import { Delimiter } from 'components/Delimiter'
import { FilterPortal } from 'components/FilterPortal'
import { Box, Flex } from 'components/Layout'
import { MediaQueryWrapper } from 'components/MediaQueryWrapper'
import { H5 } from 'components/Typography'
import { COMPOSED_FILTERS_CONFIGURATION_NAMES } from 'constants/common'
import { useModal } from 'hooks'
import {
  GA4_EVENTS,
  GA4_FILTER_TYPES,
  useGoogleAnalytics,
} from 'providers/googleAnalytics'
import { executeAvailabilityFilterEvent } from 'providers/googleAnalytics/utils/Filter/executeAvailabilityFilterEvent'
import { executeMobileFilterEvents } from 'providers/googleAnalytics/utils/Filter/executeMobileFilterEvents'
import { executePrimitiveSingleValueFilterEvent } from 'providers/googleAnalytics/utils/Filter/executePrimitiveSingleValueFilterEvent'
import { executeRegionFilterEvent } from 'providers/googleAnalytics/utils/Filter/executeRegionFilterEvent'
import { executeSessionLengthFilterEvent } from 'providers/googleAnalytics/utils/Filter/executeSessionLengthFilterEvent'
import { PAGE_MAX_WIDTH, SPACE } from 'Theme'
import { useMedia } from 'useMedia'
import { SortPortal } from '../Sort'
import { FiltersButton } from './FiltersButton'

const SlideOutModal = dynamic(() => import('components/Modal/SlideOutModal'), {
  ssr: false,
})

const { SORT } = COMPOSED_FILTERS_CONFIGURATION_NAMES

const getViewComponentValue = (value) =>
  isPlainObject(value) ? { ...value } : { value }

const isSomeFilterActive = ({ values, configuration }) =>
  some(values, (value, filterName) => {
    const isActive = get(configuration, [filterName, 'isActive'])
    return isActive instanceof Function ? isActive(value) : false
  })

const getDataForLabels = ({ values, configuration }) =>
  map(values, (value, filterName) => {
    const viewComponentName = get(configuration, [
      filterName,
      'viewComponent',
      'identification',
    ])
    const viewComponentProps = get(configuration, [
      filterName,
      'viewComponentProps',
    ])
    return {
      filterName,
      value,
      viewComponentName,
      viewComponentProps,
    }
  })

const getSortOptions = (options) =>
  Object.entries(options).map(([key, { label }]) => ({
    value: key,
    label,
  }))

const getSortComponent = ({
  configuration,
  value,
  onChange,
  isMobile,
  executeDataToDataLayer,
}) => {
  if (!configuration) {
    return null
  }

  const {
    viewComponent: ViewComponent,
    label,
    viewComponentProps,
  } = configuration

  const { options } = viewComponentProps

  const selectedKey = findKey(options, { value }) ?? 'PRICE_ASC'

  const component = (
    <ViewComponent
      {...viewComponentProps}
      options={getSortOptions(options)}
      value={selectedKey}
      onChange={({ target }) => {
        onChange({
          updatedValues: {
            [SORT]: options[target.value].value,
          },
          isImmediateSubmit: !isMobile,
        })

        if (!isMobile) {
          executeDataToDataLayer({
            event: GA4_EVENTS.SORTING,
            overrideData: {
              label: options[target.value].label,
            },
          })
        }
      }}
    />
  )
  if (isMobile) {
    return (
      <>
        <H5>{label}</H5>
        {component}
      </>
    )
  }
  return (
    <SortPortal label={label} value={options[selectedKey].label}>
      {component}
    </SortPortal>
  )
}

const getFiltersComponents = ({
  configuration,
  values,
  isMobile,
  onChange,
  sort,
  executeDataToDataLayer,
}) =>
  entries(configuration).map(([filterName, filterConfiguration], index) => {
    const {
      viewComponent: ViewComponent,
      viewComponentProps,
      label,
      isActive,
      setComponentStateOnExit,
      ...rest
    } = filterConfiguration
    const value = get(values, filterName)
    const viewComponent = (
      <ViewComponent
        key={filterName}
        onChange={(updatedValue) => {
          onChange({
            updatedValues: { [filterName]: updatedValue },
            isImmediateSubmit: !isMobile,
          })

          if (!isMobile) {
            executePrimitiveSingleValueFilterEvent({
              isEnabled:
                isArray(updatedValue) &&
                filterName !== GA4_FILTER_TYPES.AVAILABILITY &&
                filterName !== GA4_FILTER_TYPES.REGIONS &&
                filterName !== camelCase(GA4_FILTER_TYPES.SESSION_LENGTH) &&
                updatedValue.length > 0,
              newValues: updatedValue,
              previousValues: value,
              options: viewComponentProps?.options,
              type:
                filterName === 'tags'
                  ? GA4_FILTER_TYPES.STYLE
                  : snakeCase(filterName),
              executeDataToDataLayer,
            })

            executeSessionLengthFilterEvent({
              isEnabled:
                filterName === camelCase(GA4_FILTER_TYPES.SESSION_LENGTH) &&
                updatedValue.length > 0,
              newValues: updatedValue,
              previousValues: values.sessionLength,
              options: viewComponentProps?.options,
              executeDataToDataLayer,
            })

            executeRegionFilterEvent({
              isEnabled:
                filterName === GA4_FILTER_TYPES.REGIONS &&
                updatedValue.length > 0,
              newValues: updatedValue,
              previousValues: values.regions,
              options: viewComponentProps?.options,
              executeDataToDataLayer,
            })

            executeAvailabilityFilterEvent({
              isEnabled: filterName === GA4_FILTER_TYPES.AVAILABILITY,
              newValues: updatedValue,
              previousValues: values.availability,
              executeDataToDataLayer,
            })
          }
        }}
        {...getViewComponentValue(value)}
        {...viewComponentProps}
        {...rest}
      />
    )

    const isDelimiterVisible = !(index === 0 && !sort)
    if (isMobile) {
      return (
        <React.Fragment key={filterName}>
          {isDelimiterVisible && <Delimiter my={SPACE.PX_20} />}
          <H5>{label}</H5>
          {viewComponent}
        </React.Fragment>
      )
    }
    return (
      <FilterPortal
        key={filterName}
        label={label}
        isActive={isActive(value)}
        setComponentStateOnExit={setComponentStateOnExit}
        placement={viewComponentProps?.placement}
      >
        {viewComponent}
      </FilterPortal>
    )
  })

/**
 * For an implementation example, check ComposedFilters.stories.js
 */
export const ComposedFilters = ({
  configuration,
  values,
  onChange,
  onClearAll,
  ...rest
}) => {
  const previousValues = useRef(null)
  const media = useMedia()
  const { isOpen: isModalOpen, openModal, closeModal } = useModal()
  const { executeDataToDataLayer } = useGoogleAnalytics()
  const sortConfiguration = get(configuration, SORT)
  const sortValue = get(values, SORT)
  const previousSortValue = useRef(sortValue)
  const sort = getSortComponent({
    configuration: sortConfiguration,
    value: sortValue,
    onChange,
    isMobile: media.MOBILE,
    executeDataToDataLayer,
  })
  const filtersConfiguration = omit(configuration, SORT)
  const filterValues = omit(values, SORT)
  const filters = getFiltersComponents({
    configuration: filtersConfiguration,
    values: filterValues,
    isMobile: media.MOBILE,
    onChange,
    sort,
    executeDataToDataLayer,
  })

  return (
    <Box
      mt={{ MOBILE: SPACE.PX_15, DESKTOP: SPACE.PX_20 }}
      width={{ MOBILE: '100%', DESKTOP: PAGE_MAX_WIDTH.LAYOUT }}
      maxWidth="100%"
      {...rest}
    >
      <MediaQueryWrapper
        mobile={
          <>
            <FiltersButton onClick={openModal}>Sort and Filter</FiltersButton>
            <SlideOutModal
              ariaLabel="Sort and Filter"
              isAttachedToBottom
              isWithHeaderDelimiter={false}
              isHeaderSticky
              isOpen={isModalOpen}
              onModalClose={() => {
                onChange({ updatedValues: values, forceApplyChanges: true })
                closeModal()
              }}
              customFooter={() => (
                <>
                  <Delimiter />
                  <Flex p={SPACE.PX_15} justifyContent="space-between">
                    <Button
                      mr={SPACE.PX_10}
                      onClick={() => {
                        onClearAll()
                        closeModal()

                        previousValues.current = null
                        previousSortValue.current = null
                      }}
                      variant={BUTTON_VARIANT.OUTLINE}
                    >
                      Clear all
                    </Button>
                    <Button
                      onClick={() => {
                        onChange({
                          updatedValues: values,
                          forceApplyChanges: true,
                        })
                        closeModal()

                        if (
                          isSomeFilterActive({
                            values: filterValues,
                            configuration: filtersConfiguration,
                          })
                        ) {
                          executeMobileFilterEvents({
                            filterValues,
                            previousValues: previousValues.current,
                            configuration,
                            executeDataToDataLayer,
                          })
                        }

                        if (previousSortValue.current !== sortValue) {
                          const { options } =
                            sortConfiguration.viewComponentProps
                          const label = find(Object.values(options), {
                            value: sortValue,
                          })?.label

                          executeDataToDataLayer({
                            event: GA4_EVENTS.SORTING,
                            overrideData: {
                              label,
                            },
                          })
                        }

                        previousValues.current = filterValues
                        previousSortValue.current = sortValue
                      }}
                    >
                      Show results
                    </Button>
                  </Flex>
                </>
              )}
            >
              <Flex mb={SPACE.PX_20} flexDirection="column">
                {sort}
                {filters}
              </Flex>
            </SlideOutModal>
          </>
        }
        tablet={
          <Flex justifyContent="space-between" width="100%" mb={SPACE.PX_15}>
            <Flex flexDirection="row">{filters}</Flex>
            {sort}
          </Flex>
        }
      />
      {isSomeFilterActive({
        values: filterValues,
        configuration: filtersConfiguration,
      }) && (
        <Box mb={{ MOBILE: SPACE.PX_80, TABLET: SPACE.PX_15 }}>
          <Delimiter my={SPACE.PX_10} />
          <AppliedFilters
            data={getDataForLabels({
              values: filterValues,
              configuration: filtersConfiguration,
            })}
            onClearAll={(val) => {
              onClearAll(val)
              previousValues.current = null
            }}
            onChange={(val) => {
              onChange(val)
              if (previousValues.current) {
                previousValues.current =
                  val.updatedValues.length > 0
                    ? {
                        ...previousValues.current,
                        ...val.updatedValues,
                      }
                    : null
              }
            }}
          />
        </Box>
      )}
    </Box>
  )
}

ComposedFilters.propTypes = {
  configuration: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
}
