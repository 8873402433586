import PropTypes from 'prop-types'
import React from 'react'
import { Button, BUTTON_VARIANT } from 'components/Button'
import Icon from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { SPACE } from 'Theme'

export const FiltersButton = ({ children, onClick }) => (
  <Box mb={SPACE.PX_20}>
    <Button
      onClick={onClick}
      variant={BUTTON_VARIANT.OUTLINE}
      aria-label="Open Sort and Filter"
    >
      <Flex alignItems="center" justifyContent="center">
        <Box mr={SPACE.PX_10}>
          <Icon.Filter />
        </Box>
        {children}
      </Flex>
    </Button>
  </Box>
)

FiltersButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}
