import { rem } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Card, CARD_PADDING_SIZE } from 'components/Card'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { usePortalVisibility } from 'hooks'
import { COLOR_INTENT, FONT_SIZE, SPACE, Z_INDEX } from 'Theme'

const FILTER_MAX_WIDTH = rem(240)
const TOP = rem(40)

const StyledButton = styled(Flex)`
  cursor: pointer;
`

export const SortPortal = ({ children, label, value }) => {
  const { portalRef, isPortalVisible, setIsPortalVisible } =
    usePortalVisibility()
  return (
    <Flex
      position="relative"
      maxWidth={{ TABLET: FILTER_MAX_WIDTH }}
      ref={portalRef}
      mr={{ TABLET: SPACE.PX_10 }}
      mb={{ MOBILE: SPACE.PX_15, TABLET: 0 }}
    >
      <StyledButton
        onClick={() => {
          setIsPortalVisible((prevState) => !prevState)
        }}
        width="max-content"
        alignItems="center"
      >
        <Text fontSize={FONT_SIZE.PX_14}>
          {label} <Text color={COLOR_INTENT.SORT_FILTER.VALUE}>{value}</Text>
        </Text>
        <Icon.CaretDown
          size={ICON_SIZE.PX_20}
          color={COLOR_INTENT.SORT_FILTER.CARET}
        />
      </StyledButton>
      {isPortalVisible && (
        <Box
          width="max-content"
          zIndex={Z_INDEX.SELECT}
          position="absolute"
          top={TOP}
          right={0}
        >
          <Card paddingSize={CARD_PADDING_SIZE.DEFAULT}>{children}</Card>
        </Box>
      )}
    </Flex>
  )
}

SortPortal.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}
