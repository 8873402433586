import { rem } from 'polished'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import { Card, CARD_PADDING_SIZE } from 'components/Card'
import { FilterButton } from 'components/FilterButton'
import { Box, Flex } from 'components/Layout'
import { usePortalVisibility } from 'hooks'
import { SPACE, Z_INDEX } from 'Theme'

const FILTER_MAX_WIDTH = rem(240)
const TOP = rem(40)

const PLACEMENTS = {
  'bottom-right': { top: TOP, right: 0 },
  'bottom-left': { top: TOP, left: 0 },
}

interface FilterPortalProps {
  label: string
  isActive: boolean
  setComponentStateOnExit: () => void
  placement?: 'bottom-left' | 'bottom-right'
}

export const FilterPortal: React.FC<React.PWC<FilterPortalProps>> = ({
  children,
  label,
  isActive,
  setComponentStateOnExit,
  placement = 'bottom-left',
}) => {
  const { portalRef, isPortalVisible, setIsPortalVisible } =
    usePortalVisibility({ setComponentStateOnExit })
  return (
    <Flex
      position="relative"
      maxWidth={{ TABLET: FILTER_MAX_WIDTH }}
      ref={portalRef}
      mr={{ TABLET: SPACE.PX_10 }}
      mb={{ MOBILE: SPACE.PX_15, TABLET: 0 }}
    >
      <FilterButton
        isActive={isActive}
        isOpen={isPortalVisible}
        onClick={() => {
          setIsPortalVisible((prevState: boolean) => !prevState)
        }}
      >
        {label}
      </FilterButton>
      {isPortalVisible && (
        <Box
          data-test-class={DATA_TEST_CLASS.FILTER_PORTAL}
          zIndex={Z_INDEX.SELECT}
          position="absolute"
          {...PLACEMENTS[placement]}
        >
          <Card paddingSize={CARD_PADDING_SIZE.NONE}>{children}</Card>
        </Box>
      )}
    </Flex>
  )
}
