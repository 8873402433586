import {
  type FilterValues,
  type FilterConfiguration,
  type FilterTypes,
  type GoogleAnalyticsContextInterface,
} from 'providers/googleAnalytics/types'
import { type SnakeCaseToCamelCase } from 'utils/formatters'
import { executeAvailabilityFilterEvent } from './executeAvailabilityFilterEvent'
import { executeBrandFilterEvent } from './executeBrandFilterEvent'
import { executeCategoryFilterEvent } from './executeCategoryFilterEvent'
import { executeCollectionFilterEvent } from './executeCollectionFilterEvent'
import { executePriceFilterEvent } from './executePriceFilterEvent'
import { executeRegionFilterEvent } from './executeRegionFilterEvent'
import { executeSessionLengthFilterEvent } from './executeSessionLengthFilterEvent'
import { executeTagsFilterEvent } from './executeTagsFilterEvent'

interface Props
  extends Pick<GoogleAnalyticsContextInterface, 'executeDataToDataLayer'> {
  filterValues: FilterValues
  previousValues: FilterValues
  configuration: {
    [key in FilterTypes as SnakeCaseToCamelCase<key>]: FilterConfiguration
  }
}

export const executeMobileFilterEvents = ({
  filterValues,
  previousValues,
  configuration,
  executeDataToDataLayer,
}: Props) => {
  const {
    collections,
    categories,
    price,
    availability,
    brands,
    sessionLength,
    tags,
    regions,
  } = filterValues

  executeCollectionFilterEvent({
    isEnabled: Boolean(collections),
    newValues: collections,
    previousValues: previousValues?.collections,
    options: configuration.collections?.viewComponentProps.options,
    executeDataToDataLayer,
  })

  executeCategoryFilterEvent({
    isEnabled: Boolean(categories),
    newValues: categories,
    previousValues: previousValues?.categories,
    options: configuration.categories?.viewComponentProps.options,
    executeDataToDataLayer,
  })

  const { maxPrice, currencyIsoCode } = configuration.price.viewComponentProps

  executePriceFilterEvent({
    isNotMobile: true,
    updatedValues: price,
    previousValues: previousValues?.price,
    maxPrice,
    currencyIsoCode,
    executeDataToDataLayer,
  })

  executeAvailabilityFilterEvent({
    isEnabled: Boolean(availability),
    newValues: availability,
    previousValues: previousValues?.availability,
    executeDataToDataLayer,
  })

  executeBrandFilterEvent({
    isEnabled: Boolean(brands),
    newValues: brands,
    previousValues: previousValues?.brands,
    executeDataToDataLayer,
  })

  executeSessionLengthFilterEvent({
    isEnabled: Boolean(sessionLength),
    newValues: sessionLength,
    previousValues: previousValues?.sessionLength,
    options: configuration.sessionLength?.viewComponentProps.options,
    executeDataToDataLayer,
  })

  executeTagsFilterEvent({
    isEnabled: Boolean(tags),
    newValues: tags,
    previousValues: previousValues?.tags,
    executeDataToDataLayer,
  })

  executeRegionFilterEvent({
    isEnabled: Boolean(regions),
    newValues: regions,
    previousValues: previousValues?.regions,
    options: configuration.regions?.viewComponentProps.options,
    executeDataToDataLayer,
  })
}
