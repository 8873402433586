import React, { useState } from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { CheckboxGroup, CHECKBOX_SIZE } from 'components/Checkbox'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { COLOR_INTENT, SPACE } from 'Theme'
import { useMedia } from 'useMedia'
import { CHECKBOX_STATUSES } from './constants'
import { type TreeSelect } from './TreeSelect'
import { type TreeNode } from './types'

const TRANSITION_TIME = '250ms'

type CollapsibleContentWrapperProps = {
  isCollapsed: boolean
}

const CollapsibleContentWrapper = styled(Flex)<CollapsibleContentWrapperProps>`
  flex-direction: column;
  transition: ${TRANSITION_TIME};
  transform-origin: top;
  ${({ isCollapsed }) =>
    isCollapsed
      ? `
          max-height: auto;
          margin-bottom: 0;
          opacity: 1;
        `
      : `
          max-height: 0;
          opacity: 0;
        `};
`

const StyledBox = styled(Box)`
  cursor: pointer;
`

type CheckboxNodeProps = {
  node: TreeNode
  activeNodeKeys: number[]
  treeSelect: TreeSelect
  onChange: (values: number[]) => void
  getChildNodes: (children: TreeNode[]) => JSX.Element
}

export const CheckboxNode = ({
  node,
  activeNodeKeys,
  treeSelect,
  onChange,
  getChildNodes,
}: CheckboxNodeProps) => {
  const { MOBILE: isMobile } = useMedia()
  const [isCollapsed, setIsCollapsed] = useState(true)
  return (
    <Box>
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Box pr={SPACE.PX_6} width="100%">
          <CheckboxGroup.Item
            size={isMobile ? CHECKBOX_SIZE.L : CHECKBOX_SIZE.M}
            width="100%"
            onChange={() =>
              onChange(treeSelect.processNode(node.id, activeNodeKeys))
            }
            isChecked={node.data.status === CHECKBOX_STATUSES.CHECKED}
            isIndeterminant={
              node.data.status === CHECKBOX_STATUSES.INDETERMINATE
            }
          >
            {node.data.label}
          </CheckboxGroup.Item>
        </Box>
        {node.children.length > 0 && (
          <StyledBox
            onClick={() => {
              setIsCollapsed(!isCollapsed)
            }}
            data-test-id={DATA_TEST_CLASS.CHECKBOX_COLLAPSE_ARROW}
          >
            {isCollapsed ? (
              <Icon.ChevronDownSmall
                size={isMobile ? ICON_SIZE.PX_24 : ICON_SIZE.PX_20}
                color={COLOR_INTENT.GRAY_70}
              />
            ) : (
              <Icon.ChevronUpSmall
                size={isMobile ? ICON_SIZE.PX_24 : ICON_SIZE.PX_20}
                color={COLOR_INTENT.GRAY_70}
              />
            )}
          </StyledBox>
        )}
      </Flex>
      {node.children.length > 0 && (
        <CollapsibleContentWrapper isCollapsed={!isCollapsed}>
          {getChildNodes(node.children)}
        </CollapsibleContentWrapper>
      )}
    </Box>
  )
}
