import { difference, find, get } from 'lodash'
import {
  type FilterValues,
  GA4_FILTER_TYPES,
  type GoogleAnalyticsContextInterface,
  type FilterConfiguration,
} from 'providers/googleAnalytics/types'
import { mapDataToFilterEvent } from '../helpers'

interface Props
  extends Pick<GoogleAnalyticsContextInterface, 'executeDataToDataLayer'> {
  isEnabled: boolean
  newValues: FilterValues['sessionLength']
  previousValues: FilterValues['sessionLength']
  options: FilterConfiguration['viewComponentProps']['options']
}

export const executeSessionLengthFilterEvent = ({
  isEnabled,
  newValues,
  previousValues,
  options,
  executeDataToDataLayer,
}: Props) => {
  if (!isEnabled) {
    return
  }

  const updatedDataValues = difference(newValues, previousValues)

  if (!updatedDataValues.length) {
    return
  }

  const updatedDataLabels = updatedDataValues.map((value): string =>
    get(
      find(options, {
        value,
      }),
      'label'
    )
  )

  updatedDataLabels.forEach((updatedLabel) => {
    executeDataToDataLayer(
      mapDataToFilterEvent({
        type: GA4_FILTER_TYPES.SESSION_LENGTH,
        action: updatedLabel,
      })
    )
  })
}
