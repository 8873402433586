import { difference, lowerCase } from 'lodash'
import {
  type FilterValues,
  GA4_FILTER_TYPES,
  type GoogleAnalyticsContextInterface,
} from 'providers/googleAnalytics/types'
import { mapDataToFilterEvent } from '../helpers'

interface Props
  extends Pick<GoogleAnalyticsContextInterface, 'executeDataToDataLayer'> {
  isEnabled: boolean
  newValues: FilterValues['tags']
  previousValues: FilterValues['tags']
}

export const executeTagsFilterEvent = ({
  isEnabled,
  newValues,
  previousValues,
  executeDataToDataLayer,
}: Props) => {
  if (!isEnabled) {
    return
  }

  const updatedDataLabels = difference(newValues, previousValues)

  updatedDataLabels.forEach((updatedLabel) => {
    executeDataToDataLayer(
      mapDataToFilterEvent({
        type: GA4_FILTER_TYPES.STYLE,
        action: lowerCase(updatedLabel),
      })
    )
  })
}
