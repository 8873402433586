import { difference, isArray, lowerCase } from 'lodash'
import {
  type FilterValues,
  GA4_FILTER_TYPES,
  type GoogleAnalyticsContextInterface,
} from 'providers/googleAnalytics/types'
import { formatExpertAvailability, mapDataToFilterEvent } from '../helpers'

interface Props
  extends Pick<GoogleAnalyticsContextInterface, 'executeDataToDataLayer'> {
  isEnabled: boolean
  newValues: FilterValues['availability']
  previousValues: FilterValues['availability']
}

export const executeAvailabilityFilterEvent = ({
  isEnabled,
  newValues,
  previousValues,
  executeDataToDataLayer,
}: Props) => {
  if (!isEnabled) {
    return
  }

  if (isArray(newValues) && newValues.length > 0) {
    const updatedDataLabels = difference(newValues, previousValues)

    updatedDataLabels.forEach((updatedLabel) => {
      executeDataToDataLayer(
        mapDataToFilterEvent({
          type: GA4_FILTER_TYPES.AVAILABILITY,
          action: lowerCase(updatedLabel),
        })
      )
    })
  }

  if (newValues.availabilityFrom && newValues.availabilityTo) {
    executeDataToDataLayer(
      mapDataToFilterEvent({
        type: GA4_FILTER_TYPES.AVAILABILITY,
        action: `${formatExpertAvailability(
          new Date(newValues.availabilityFrom)
        )} - ${formatExpertAvailability(new Date(newValues.availabilityTo))}`,
      })
    )
  }
}
