import PropTypes from 'prop-types'
import React from 'react'
import { Flex } from 'components/Layout'
import {
  COMPOSED_FILTERS_CONFIGURATION_NAMES,
  FILTER_VIEW_COMPONENT_NAMES,
} from 'constants/common'
import { useMedia } from 'useMedia'
import { ClearAll } from './ClearAll'

export const AppliedFiltersWrapper = ({ data, children, onClearAll }) => {
  const media = useMedia()
  const clearAllComponent = <ClearAll onClick={onClearAll} />
  return (
    <Flex
      maxWidth="100%"
      overflow="auto"
      flexWrap={{ MOBILE: 'nowrap', TABLET: 'wrap' }}
      position={{ MOBILE: 'absolute', TABLET: 'initial' }}
      left={{ MOBILE: 0 }}
    >
      {onClearAll && media.MOBILE && clearAllComponent}
      {data.map(({ filterName, value, viewComponentName, ...rest }) =>
        children({
          filterName,
          value,
          viewComponentName,
          ...rest,
        })
      )}
      {onClearAll && !media.MOBILE && clearAllComponent}
    </Flex>
  )
}

AppliedFiltersWrapper.propTypes = {
  children: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      filterName: PropTypes.oneOf(
        Object.values(COMPOSED_FILTERS_CONFIGURATION_NAMES)
      ).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.shape({
          availabilityFrom: PropTypes.string,
          availabilityTo: PropTypes.string,
        }),
        PropTypes.shape({
          priceFrom: PropTypes.number,
          priceTo: PropTypes.number,
        }),
      ]).isRequired,
      viewComponentName: PropTypes.oneOf(
        Object.values(FILTER_VIEW_COMPONENT_NAMES)
      ).isRequired,
      maxPrice: PropTypes.number,
    })
  ).isRequired,
  onClearAll: PropTypes.func.isRequired,
}
