import { castArray } from 'lodash'
import { type NextRouter } from 'next/router'
import queryString from 'query-string'
import { type FiltersStateValue } from 'components/ComposedFilters'
import {
  COMPOSED_FILTERS_CONFIGURATION_NAMES,
  MULTI_COLLECTION_WIDGET_LIMIT,
} from 'constants/common'
import { ROUTE } from 'constants/routes'
import { type ProductCategory } from 'types/graphql-generated'
import { silentPushUrl } from 'utils/pushUrl'

interface ShowroomComposedFilterParams {
  page?: number
  price?: {
    priceFrom?: number
    priceTo?: number
  }
  collections?: string[]
  availability?: string[]
  brands?: string[]
  field?: string
  direction?: string
  maxPrice?: number
  categories?: number[]
  sort?: {
    direction?: string
    field?: string
  }
}

interface ShowroomSearchParams {
  priceFrom?: string
  priceTo?: string
  collections?: FiltersStateValue
  categories?: FiltersStateValue
  availability?: FiltersStateValue
  brands?: FiltersStateValue
  field?: string
  direction?: string
  statuses?: FiltersStateValue
}

const FILTER_MAX_PRICE = 150000

export const prepareShowroomComposedFilterParams = ({
  page = 1,
  price,
  collections,
  categories,
  availability,
  brands,
  sort,
  maxPrice = FILTER_MAX_PRICE,
  ...rest
}: ShowroomComposedFilterParams) => ({
  ...(page > 1 && { page }),
  ...(price?.priceFrom !== undefined &&
    price.priceFrom !== 0 && { priceFrom: price.priceFrom }),
  ...(price?.priceTo !== undefined &&
    price.priceTo !== maxPrice && { priceTo: price.priceTo }),
  ...(collections?.length && { collections }),
  ...(categories?.length && { categories }),
  ...(availability?.length && { availability }),
  ...(brands?.length && { brands }),
  ...(sort?.direction && { direction: sort.direction }),
  ...(sort?.field && { field: sort.field }),
  ...rest,
})

export const getShowroomRouteFromSlug = (slug?: string) => {
  if (!slug) {
    return null
  }

  if (slug === 'shop') {
    return ROUTE.SHOWROOM()
  }

  if (slug === 'products') {
    return ROUTE.SHOWROOM_PRODUCTS()
  }

  return null
}

export const silentPushCmsPageUrl = ({
  router,
  params = {},
  cmsPageSlug,
  maxPrice,
}: {
  router: NextRouter
  params: ShowroomComposedFilterParams
  cmsPageSlug: string
  maxPrice?: number
}) => {
  const { url } = queryString.parseUrl(router.asPath)
  const showroomRouteSlug = getShowroomRouteFromSlug(cmsPageSlug)
  return silentPushUrl({
    router,
    route: showroomRouteSlug || url,
    params: prepareShowroomComposedFilterParams({ ...params, maxPrice }),
  })
}

export const parseSearchParamsComposedFilters = ({
  priceFrom,
  priceTo,
  collections,
  categories,
  availability,
  brands,
  field,
  direction,
  statuses,
}: ShowroomSearchParams) => ({
  ...((Number(priceFrom) >= 0 || Number(priceTo) >= 0) && {
    [COMPOSED_FILTERS_CONFIGURATION_NAMES.PRICE]: {
      ...(priceFrom !== undefined && { priceFrom: Number(priceFrom) }),
      ...(priceTo !== undefined && { priceTo: Number(priceTo) }),
    },
  }),
  ...(categories?.length && {
    [COMPOSED_FILTERS_CONFIGURATION_NAMES.CATEGORIES]: castArray(
      categories
    ).map((category) => Number(category)),
  }),
  ...(statuses?.length && {
    [COMPOSED_FILTERS_CONFIGURATION_NAMES.STATUSES]: castArray(statuses),
  }),
  ...(collections?.length && {
    [COMPOSED_FILTERS_CONFIGURATION_NAMES.COLLECTIONS]: castArray(collections),
  }),
  ...(availability?.length && {
    [COMPOSED_FILTERS_CONFIGURATION_NAMES.AVAILABILITY]:
      castArray(availability),
  }),
  ...(brands?.length && {
    [COMPOSED_FILTERS_CONFIGURATION_NAMES.BRANDS]: castArray(brands),
  }),
  ...((field || direction) && {
    [COMPOSED_FILTERS_CONFIGURATION_NAMES.SORT]: {
      ...(field && { field }),
      ...(direction && { direction }),
    },
  }),
})

interface GetPageSizeProps {
  isTablet: boolean
}

export const getPageSize = ({ isTablet }: GetPageSizeProps) => {
  if (isTablet) {
    return MULTI_COLLECTION_WIDGET_LIMIT.TABLET
  }

  return MULTI_COLLECTION_WIDGET_LIMIT.DEFAULT
}

interface GetRedundantItemsCountProps {
  isMobile: boolean
  isTablet: boolean
  pageSize: number
  isFilterApplied: boolean
  listingWidgetsCount: number
}

const INFO_LISTING_WIDGET_SIZE = 2

/**
 * @description Returns number of items which are in new not full row
 * - Example: Tablet viewport has three columns and displays 33 items {MULTI_COLLECTION_WIDGET_LIMIT.TABLET} in total.
 * Since MultiCollectionWidget has 2 listing widgets and each of them takes 2 item positions `33 + 2 * 2 = 37` => `37 % 3 {products_per_row} = 1`
 */
export const getRedundantItemsCount = ({
  isMobile,
  isTablet,
  pageSize,
  isFilterApplied,
  listingWidgetsCount,
}: GetRedundantItemsCountProps) => {
  // When filter is applied we don't show any listing widgets
  if (isFilterApplied) {
    return 0
  }

  const totalPositionsCount =
    pageSize + listingWidgetsCount * INFO_LISTING_WIDGET_SIZE

  if (isMobile) {
    const mobileModulo = totalPositionsCount % 2

    return listingWidgetsCount === 1 ? mobileModulo + 2 : mobileModulo
  }

  if (isTablet) {
    return totalPositionsCount % 3
  }

  // Desktop
  return totalPositionsCount % 4
}

const formatNoSiblingsLevelChildren = (
  children: ProductCategory[]
): ProductCategory[] =>
  children.map((category) => ({
    ...category,
    parentId: null,
  }))

export const formatCategoriesForCollapsibleTreeSelect = (
  categoriesOne: ProductCategory[]
) => {
  if (categoriesOne.length === 1) {
    const { children } = categoriesOne[0]

    const formatted = formatNoSiblingsLevelChildren(children)
    if (formatted.length === 1) {
      return formatNoSiblingsLevelChildren(formatted[0].children)
    }

    return formatted
  }

  return categoriesOne
}

type FilterCollectionCategories = {
  categoriesOne: ProductCategory[]
  categoriesTwo: ProductCategory[]
  categoriesThree: ProductCategory[]
}

export const filterCollectionCategories = ({
  categoriesOne,
  categoriesTwo,
  categoriesThree,
}: FilterCollectionCategories) => {
  const categoriesTwoIds = categoriesTwo?.map(({ id }) => id) ?? []
  const categoriesThreeIds = categoriesThree?.map(({ id }) => id) ?? []
  return categoriesOne.map((categoryOne) => {
    if (!categoryOne.children.length) {
      return categoryOne
    }
    const filteredCategoriesTwo = categoryOne.children.reduce(
      (acc, categoryTwo) => {
        if (!categoriesTwoIds.includes(categoryTwo.id)) {
          return acc
        }
        if (!categoryTwo.children.length) {
          return [...acc, categoryTwo]
        }
        const filteredCategoriesThree = categoryTwo.children.filter(
          (categoryThree) => categoriesThreeIds.includes(categoryThree.id)
        )
        return [...acc, { ...categoryTwo, children: filteredCategoriesThree }]
      },
      []
    )
    return { ...categoryOne, children: filteredCategoriesTwo }
  })
}
