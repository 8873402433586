import PropTypes from 'prop-types'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Button, BUTTON_VARIANT } from 'components/Button'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Flex } from 'components/Layout'
import { COLOR_INTENT, SPACE } from 'Theme'

const TRANSITION_DURATION = '300ms'
const TRANSITION_TIMING_FUNCTION = 'cubic-bezier(0.4, 0, 0.2, 1)'

const getTransition = (property) =>
  `${property} ${TRANSITION_DURATION} ${TRANSITION_TIMING_FUNCTION}`

const StyledButton = styled(Button)`
  background-color: ${COLOR_INTENT.APPLIED_FILTER_TAG.BACKGROUND};
  border-color: ${COLOR_INTENT.APPLIED_FILTER_TAG.BORDER};
  transition: ${getTransition('background-color')},
    ${getTransition('border-color')};
  &:hover {
    background-color: ${COLOR_INTENT.APPLIED_FILTER_TAG.BACKGROUND_HOVER};
    border-color: ${COLOR_INTENT.APPLIED_FILTER_TAG.BORDER_HOVER};
  }
  color: ${COLOR_INTENT.APPLIED_FILTER_TAG.TEXT};
`

export const AppliedFilterTag = ({ children, onClick }) => (
  <Flex
    data-test-class={DATA_TEST_CLASS.APPLIED_FILTER_TAG}
    width="fit-content"
    my={SPACE.PX_5}
    mr={SPACE.PX_10}
  >
    <StyledButton variant={BUTTON_VARIANT.OUTLINE} onClick={onClick}>
      <Flex alignItems="center" justifyContent="space-between">
        {children}
        <Flex pl={SPACE.PX_10}>
          <Icon.Cross
            size={ICON_SIZE.PX_20}
            color={COLOR_INTENT.APPLIED_FILTER_TAG.ICON_CROSS}
          />
        </Flex>
      </Flex>
    </StyledButton>
  </Flex>
)

AppliedFilterTag.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
