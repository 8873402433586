import React from 'react'
import type {
  DisplayProps,
  FlexDirectionProps,
  MarginProps,
} from 'styled-system'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { COLOR_INTENT, FONT_SIZE, SPACE } from 'Theme'
import { RadioItem, RADIO_SIZE, type RadioItemProps } from './RadioItem'

export interface RadioGroupProps
  extends Pick<RadioItemProps, 'size' | 'onChange' | 'value'> {
  name?: string
  label?: string
  hint?: string
  isDisabled?: boolean
  wrapperProps?: DisplayProps & MarginProps
  itemsWrapperProps?: FlexDirectionProps & {
    gap: (typeof SPACE)[keyof typeof SPACE]
  }
}

interface RadioGroupExtensions {
  Item: typeof RadioItem
}

export const RadioGroup: React.FC<React.PWC<RadioGroupProps>> &
  RadioGroupExtensions = ({
  value,
  onChange,
  children,
  name,
  size = RADIO_SIZE.M,
  label,
  hint,
  isDisabled,
  wrapperProps = {
    display: 'inline-flex',
  },
  itemsWrapperProps,
}) => (
  <Flex flexDirection="column" {...wrapperProps}>
    {label && (
      <Text
        color={COLOR_INTENT.GRAY_90}
        marginBottom={hint !== undefined ? SPACE.PX_5 : ''}
      >
        {label}
      </Text>
    )}
    {hint && (
      <Text
        fontSize={FONT_SIZE.PX_12}
        color={COLOR_INTENT.GRAY_70}
        marginBottom={SPACE.PX_5}
      >
        {hint}
      </Text>
    )}
    <Flex flexDirection="column" {...itemsWrapperProps}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null
        }
        if (child.type !== RadioItem) {
          return child
        }
        return React.cloneElement<RadioItemProps>(child, {
          onChange,
          groupValue: value,
          name,
          size,
          isDisabled: child?.props.isDisabled || isDisabled,
        })
      })}
    </Flex>
  </Flex>
)

RadioGroup.Item = RadioItem
