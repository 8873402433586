import PropTypes from 'prop-types'
import React from 'react'
import { RadioGroup, RADIO_SIZE } from 'components/Radio'
import { FILTER_VIEW_COMPONENT_NAMES } from 'constants/common'
import { useMedia } from 'useMedia'

export const Sort = ({ options, onChange, value: groupValue }) => {
  const media = useMedia()
  return (
    <RadioGroup
      value={groupValue}
      onChange={onChange}
      size={media.MOBILE ? RADIO_SIZE.L : RADIO_SIZE.M}
      name="sort"
    >
      {options.map(({ label, value }) => (
        <RadioGroup.Item value={value} key={value} name={label}>
          {label}
        </RadioGroup.Item>
      ))}
    </RadioGroup>
  )
}

Sort.identification = FILTER_VIEW_COMPONENT_NAMES.SORT

Sort.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}
