import { rem } from 'polished'
import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { border, space } from 'styled-system'
import { Text } from 'components/Typography'
import {
  BORDER_WIDTH,
  COLOR_INTENT,
  FONT_SIZE,
  getBorderColor,
  GRADIENT,
  RADIUS,
  SPACE,
} from 'Theme'

export const RADIO_SIZE = {
  M: rem(16),
  L: rem(20),
}

const RADIO_BACKGROUND = `linear-gradient(${GRADIENT.GRADIENT_2}), ${COLOR_INTENT.TEXT_ON_DARK};`

const RadioWrapper = styled('div')`
  display: inline-flex;
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: ${RADIUS.CIRCLE};
  background: ${({ backgroundColor }) => backgroundColor};
  ${({ borderWidth, borderColor }) => `
    border: ${borderWidth} solid ${borderColor};
  `}
  ${({ isDisabled, isChecked }) => isDisabled && isChecked && `opacity: 0.5;`}
  ${({ size }) =>
    size &&
    `
    height: ${size};
    width: ${size};
  `}
`

const Label = styled('label')`
  display: ${({ isFullWidth }) => (isFullWidth ? 'flex' : 'inline-flex')};
  align-items: center;
  margin: ${SPACE.PX_5} 0;
  input {
    display: none;
  }
  ${({ isDisabled }) =>
    !isDisabled &&
    `
    &:hover {
    cursor: pointer;
    ${RadioWrapper} {
      background: linear-gradient(${GRADIENT.GRADIENT_2_HOVER}), ${COLOR_INTENT.TEXT_ON_DARK};
    }
  }
  `}
  ${space}
  ${border}
`

type RadioSize = (typeof RADIO_SIZE)[keyof typeof RADIO_SIZE]

const getFontSize = (size: RadioSize) => {
  if (size === RADIO_SIZE.L) {
    return FONT_SIZE.PX_16
  }
  return FONT_SIZE.PX_14
}

const getBackgroundColor = (isDisabled: boolean, isChecked: boolean) => {
  if (isDisabled) {
    if (isChecked) {
      return COLOR_INTENT.TEXT_ON_DARK
    }
    return COLOR_INTENT.GRAY_10
  }
  return RADIO_BACKGROUND
}

export interface RadioItemProps {
  children?: React.ReactNode
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  isDisabled?: boolean
  isWarning?: boolean
  isError?: boolean
  value?: number | string | boolean
  groupValue?: number | string | boolean
  name?: string
  size?: RadioSize
  isFullWidth?: boolean
}

export const RadioItem: React.FC<RadioItemProps> = ({
  value,
  children,
  isDisabled = false,
  isWarning = false,
  isError = false,
  onChange,
  name,
  size,
  groupValue,
  isFullWidth,
  ...props
}) => {
  const isChecked = value === groupValue
  const fontSize = getFontSize(size)
  const borderWidth = isChecked ? BORDER_WIDTH.PX_4 : BORDER_WIDTH.PX_1
  const backgroundColor = getBackgroundColor(isDisabled, isChecked)

  return (
    <Label
      isDisabled={isDisabled}
      isFullWidth={isFullWidth}
      data-test-id={DATA_TEST_ID.RADIO_ITEM}
      name={name}
      {...props}
    >
      <input
        type="radio"
        name={name}
        checked={isChecked}
        value={typeof value === 'boolean' ? value.toString() : value}
        disabled={isDisabled}
        onChange={onChange}
      />
      <RadioWrapper
        isDisabled={isDisabled}
        size={size}
        isChecked={isChecked}
        borderWidth={borderWidth}
        borderColor={getBorderColor({
          isFocusedOrChecked: isChecked,
          isError,
          isWarning,
        })}
        backgroundColor={backgroundColor}
      />
      {typeof children === 'string' ? (
        <Text
          pl={SPACE.PX_10}
          color={isDisabled ? COLOR_INTENT.GRAY_50 : COLOR_INTENT.GRAY_90}
          fontSize={fontSize}
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </Label>
  )
}
