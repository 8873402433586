import {
  type FilterValues,
  type CurrencyTypes,
  type GoogleAnalyticsContextInterface,
  GA4_FILTER_TYPES,
} from 'providers/googleAnalytics/types'
import { mapDataToFilterEvent, formatPriceValueChange } from '../helpers'

interface Props
  extends Pick<GoogleAnalyticsContextInterface, 'executeDataToDataLayer'> {
  isNotMobile: boolean
  updatedValues: FilterValues['price']
  previousValues: FilterValues['price']
  maxPrice: number
  currencyIsoCode: CurrencyTypes
}

export const executePriceFilterEvent = ({
  isNotMobile,
  updatedValues,
  previousValues,
  maxPrice,
  currencyIsoCode,
  executeDataToDataLayer,
}: Props) => {
  if (
    isNotMobile &&
    // Look if priceFrom or priceTo isn't default value && if it has changed from the previous set
    (updatedValues.priceFrom !== 0 || updatedValues.priceTo !== maxPrice) &&
    (previousValues?.priceFrom !== updatedValues.priceFrom ||
      previousValues?.priceTo !== updatedValues.priceTo)
  ) {
    executeDataToDataLayer(
      mapDataToFilterEvent({
        type: GA4_FILTER_TYPES.PRICE,
        action: formatPriceValueChange({
          currencyIsoCode,
          ...updatedValues,
        }),
      })
    )
  }
}
