import { rem } from 'polished'
import type React from 'react'
import styled, { css } from 'styled-components'
import type {
  DisplayProps,
  SpaceProps,
  FlexDirectionProps,
  FlexboxProps,
  PositionProps,
  LayoutProps,
} from 'styled-system'
import { Flex } from 'components/Layout'
import { bordered } from 'components/mixins'
import { BOX_SHADOW, COLOR, RADIUS, SPACE } from 'Theme'

export const CARD_PADDING_SIZE = {
  NONE: 0,
  SMALL: SPACE.PX_10,
  DEFAULT: SPACE.PX_20,
  LARGE: SPACE.PX_30,
  XLARGE: SPACE.PX_40,
  XXLARGE: rem(50),
}

export const CARD_ELEVATION = {
  NONE: 'none',
  ELEVATION_1: '0px 5px 10px rgba(24, 27, 25, 0.07)',
  ELEVATION_2: '0px 5px 20px rgba(24, 27, 25, 0.08)',
  ELEVATION_3: '0px 5px 30px rgba(24, 27, 25, 0.1)',
  ELEVATION_4: '0px 5px 40px rgba(24, 27, 25, 0.1)',
  ELEVATION_5: '0px 5px 20px rgba(0, 0, 0, 0.1)',
}

export interface CardProps
  extends SpaceProps,
    DisplayProps,
    FlexDirectionProps,
    FlexboxProps,
    PositionProps,
    LayoutProps,
    React.HTMLAttributes<HTMLDivElement> {
  paddingSize?: (typeof CARD_PADDING_SIZE)[keyof typeof CARD_PADDING_SIZE]
  elevation?: (typeof CARD_ELEVATION)[keyof typeof CARD_ELEVATION]
  hasBorders?: boolean
  minWidth?: string
  ref?: React.ForwardedRef<HTMLDivElement>
  as?: string
}

export const Card: React.FC<React.PWC<CardProps>> = styled(Flex)`
  width: 100%;
  min-height: ${SPACE.PX_20};
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  min-width: ${({ minWidth }) => minWidth ?? SPACE.PX_20};
  box-shadow: ${BOX_SHADOW.ELEVATION_6};
  border-radius: ${RADIUS.PX_10};
  padding: ${SPACE.PX_20};
  background-color: ${COLOR.WHITE};
  ${({ hasBorders }) =>
    hasBorders &&
    `
   ${bordered}
    box-shadow: none;
  `}
  ${({ paddingSize }) =>
    paddingSize !== undefined &&
    `
    padding: ${paddingSize};
  `}
  ${({ elevation }) =>
    elevation &&
    `
    box-shadow: ${elevation};
  `}
`
