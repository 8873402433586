// Allow usage of generator functions
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-classes-per-file */

import { type Node, type TreeNode, type TreeNodeData } from './types'

export class GeneralTreeNode implements Node {
  id: number

  data: TreeNodeData

  readonly parent: TreeNode

  readonly children: TreeNode[]

  constructor(id: number, data: TreeNodeData, parent: TreeNode = null) {
    this.id = id
    this.data = data
    this.parent = parent
    this.children = []
  }

  hasChildren() {
    return this.children.length > 0
  }

  updateData(data: Partial<TreeNodeData>) {
    this.data = { ...this.data, ...data }
  }
}

export class GeneralTree {
  root: TreeNode

  constructor(id: number, data: TreeNodeData) {
    this.root = new GeneralTreeNode(id, data)
  }

  *preOrderTraversal(node = this.root) {
    yield node
    if (node.children.length) {
      for (const child of node.children) {
        yield* this.preOrderTraversal(child)
      }
    }
  }

  /**
   * @description Inserts node
   * @param {number | GeneralTreeNode} parent
   * @param {number} id
   * @param {TreeNodeData} data
   * @returns {GeneralTreeNode | undefined }
   */
  insert(
    parent: number | GeneralTreeNode,
    id: number,
    data: TreeNodeData
  ): GeneralTreeNode | undefined {
    let currentNode
    const isParentId = typeof parent === 'number'
    const preorder = this.preOrderTraversal(isParentId ? undefined : parent)
    while (!currentNode?.done) {
      currentNode = preorder.next()
      if (currentNode?.value?.id === (isParentId ? parent : parent.id)) {
        const child = new GeneralTreeNode(id, data, currentNode.value)
        currentNode.value.children.push(child)
        return child
      }
    }
    return undefined
  }

  /**
   * @description Returns node by id
   * @param {number} id
   * @returns {TreeNode | undefined}
   */
  find(id: number): GeneralTreeNode | undefined {
    let currentNode
    const preorder = this.preOrderTraversal()
    while (!currentNode?.done) {
      currentNode = preorder.next()
      if (currentNode.value?.id === id) {
        return currentNode.value
      }
    }
    return undefined
  }
}
