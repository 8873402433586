import { CHECKBOX_STATUSES } from './constants'
import { GeneralTree } from './GeneralTree'
import { type TreeNode, type TreeNodeData } from './types'

export class TreeSelect {
  tree: GeneralTree

  constructor(id: number, data: TreeNodeData) {
    this.tree = new GeneralTree(id, data)
  }

  /**
   * @description Returns top level parent node
   * @param {number} id
   * @returns {TreeNode}
   */
  getTreeRoot(id: number): TreeNode {
    const node = this.tree.find(id)
    if (
      node.parent?.id &&
      node.parent.data.status !== CHECKBOX_STATUSES.INACTIVE
    ) {
      return this.getTreeRoot(node.parent.id)
    }
    return node
  }

  /**
   * @description Returns ids of given node
   * @param {number} nodeId
   * @returns {number[]}
   */
  getNodeIds(nodeId: number): number[] {
    const nodeIds = []
    let currentNode
    const rootNode = this.tree.find(nodeId)
    const preorder = this.tree.preOrderTraversal(rootNode)
    while (!currentNode?.done) {
      currentNode = preorder.next()
      if (currentNode.value) {
        nodeIds.push(currentNode.value.id)
      }
    }
    return nodeIds
  }

  /**
   * @description Returns ids of all checked nodes
   * @param {number} id
   * @param {number[]} ids
   * @returns {number[]}
   */
  getCheckedIds(id: number, ids: number[] = []): number[] {
    const checkedIds = ids
    // find root parent - id from params is id of root node
    const node = this.tree.find(id)
    if (node.children.length) {
      if (
        node.children.every(
          (child: TreeNode) => child.data.status === CHECKBOX_STATUSES.UNCHECKED
        )
      ) {
        return checkedIds
      }
      if (
        node.children.every(
          (child: TreeNode) => child.data.status === CHECKBOX_STATUSES.CHECKED
        )
      ) {
        checkedIds.push(node.id)
        return checkedIds
      }
      // in the case if all nodes status is not CHECKED or UNCHECKED we need to iterate over nodes and push CHECKED nodes
      // in the case of INDETERMINATE we need to use recursive and iterate over INDETERMINATE nodes as
      node.children.forEach((child: TreeNode) => {
        if (child.data.status === CHECKBOX_STATUSES.CHECKED) {
          return checkedIds.push(child.id)
        }
        if (child.data.status === CHECKBOX_STATUSES.INDETERMINATE) {
          return this.getCheckedIds(child.id, checkedIds)
        }
        return checkedIds
      })
    }
    // if its only root node ( without children ) we need to check if node status is checked and if yes we need to push this node into checkedIds
    // in the case that root node status is UNCHECKED we dont want to update him because it will be only removed from values
    if (node.data.status === CHECKBOX_STATUSES.CHECKED) {
      checkedIds.push(node.id)
    }
    return checkedIds
  }

  /**
   * @description Update all children statuses of current node
   * @param {number} id
   * @param {TreeNodeData['status']} status
   * @returns {boolean}
   */
  updateChildrenNodeStatus(
    id: number,
    status: TreeNodeData['status']
  ): boolean {
    const node = this.tree.find(id)
    node.data.status = status
    if (node.children) {
      node.children.forEach((child: TreeNode) => {
        this.updateChildrenNodeStatus(child.id, status)
      })
    }
    return true
  }

  setDefaultParentStatus(treeSelect: TreeSelect, node: TreeNode) {
    const parentNode = treeSelect.tree.find(node.id)
    if (!parentNode || parentNode.data.status === CHECKBOX_STATUSES.INACTIVE) {
      return
    }
    parentNode.updateData({ status: CHECKBOX_STATUSES.INDETERMINATE })
    if (parentNode.parent?.id) {
      this.setDefaultParentStatus(treeSelect, parentNode.parent)
    }
  }

  setDefaultChildStatus(treeSelect: TreeSelect, node: TreeNode) {
    const parentNode = treeSelect.tree.find(node.id)
    if (!parentNode) {
      return
    }
    parentNode.updateData({ status: CHECKBOX_STATUSES.CHECKED })
    if (parentNode.children.length > 0) {
      parentNode.children.forEach((child: TreeNode) =>
        this.setDefaultChildStatus(treeSelect, child)
      )
    }
  }

  setDefaultNodeStatus(treeSelect: TreeSelect, value: number) {
    const node = treeSelect.tree.find(value)
    if (!node) {
      return
    }
    node.updateData({ status: CHECKBOX_STATUSES.CHECKED })
    if (node.children.length > 0) {
      this.setDefaultChildStatus(treeSelect, node)
    }
    if (node.parent) {
      this.setDefaultParentStatus(treeSelect, node.parent)
    }
  }

  setDefaultNodeStatuses(treeSelect: TreeSelect, values: number[]) {
    values.forEach((value) => {
      this.setDefaultNodeStatus(treeSelect, value)
    })
  }

  /**
   * @description Update all parents statuses of current node
   * @param {number} id
   * @param {TreeNodeData['status']} status
   * @returns {boolean}
   */
  updateParentNodeStatus(id: number, status: TreeNodeData['status']): boolean {
    const node = this.tree.find(id)
    node.data.status = status
    if (node.parent && node.parent.data.status !== CHECKBOX_STATUSES.INACTIVE) {
      if (
        node.parent.children.every(
          (child: TreeNode) => child.data.status === status
        )
      ) {
        return this.updateParentNodeStatus(node.parent.id, status)
      }
      return this.updateParentNodeStatus(
        node.parent.id,
        CHECKBOX_STATUSES.INDETERMINATE
      )
    }
    return true
  }

  /**
   * @description Update statuses for parent and children nodes
   * @param {number} id
   * @returns {void}
   */
  updateNodeStatus(id: number): void {
    const node = this.tree.find(id)
    const status =
      node.data.status === CHECKBOX_STATUSES.CHECKED
        ? CHECKBOX_STATUSES.UNCHECKED
        : CHECKBOX_STATUSES.CHECKED
    this.updateChildrenNodeStatus(id, status)
    this.updateParentNodeStatus(id, status)
  }

  /**
   * @description Returns the udpated tree nodes ids filtered from filter activeNodeIds
   * @param {number} id
   * @param {number[]} activeNodeIds
   * @returns {number[]}
   */
  updateNode(id: number, activeNodeIds: number[]): number[] {
    // find root node by current clicked tree node
    const rootNode = this.getTreeRoot(id)
    // get all ids from top parent node
    const nodeIds = this.getNodeIds(rootNode.id)
    // get updateIds from actual parent node
    const checkedIds = this.getCheckedIds(rootNode.id)
    // filter all nodeIds from composed filter values
    const filteredValues = activeNodeIds.filter(
      (activeNodeId) => !nodeIds.includes(activeNodeId)
    )
    // return filtered values spread with updatedIds
    return [...filteredValues, ...checkedIds]
  }

  /**
   * @description Returns the processed tree nodes ids array.
   * @param {number} id
   * @param {number[]} values
   * @returns {number[]}
   */
  processNode(id: number, values: number[]): number[] {
    this.updateNodeStatus(id)
    return this.updateNode(id, values)
  }
}
