import { isEqual, isMatchWith, omit } from 'lodash'
import { useCallback, useState } from 'react'
import { deepClearObjectValues, deepFlattenObject } from 'utils/formatters'

export type FiltersStateValue<T = string> = T | T[] | null

export type ComposedFiltersStateManagementSubmitHandler = (
  updatedValues: any
) => void

export const useComposedFiltersStateManagement = <
  T extends Record<string, any>,
>({
  initialValues,
  submit = () => {},
}: {
  initialValues: T
  submit: ComposedFiltersStateManagementSubmitHandler
}) => {
  const [filterValues, setFilterValues] = useState(initialValues)

  const onChange = useCallback(
    ({
      updatedValues = {},
      isImmediateSubmit = true,
      forceApplyChanges = false,
    }) => {
      if (
        !forceApplyChanges &&
        isMatchWith(filterValues, updatedValues, isEqual)
      ) {
        return
      }
      setFilterValues((prev) => {
        const updatedFilterValues = {
          ...prev,
          ...updatedValues,
        }

        if (JSON.stringify(updatedFilterValues) === JSON.stringify(prev)) {
          return prev
        }

        return updatedFilterValues
      })

      if (isImmediateSubmit) {
        submit(deepFlattenObject(updatedValues))
      }
    },
    [submit, filterValues]
  )
  /**
   * @description Set every object property of the filter state to undefined except for exception which have default state
   * @param exceptions {Object[]} - Exceptions that have default state and that aren't set to undefined
   * */
  const onClearAll = useCallback(
    ({ exceptions = [] } = {}) => {
      onChange({
        updatedValues: {
          ...deepClearObjectValues(
            omit(
              initialValues,
              exceptions.map(({ key }) => key)
            )
          ),
          ...exceptions.reduce(
            (obj, { key, state }) => ({
              ...obj,
              [key]: state,
            }),
            {}
          ),
        },
      })
    },
    [initialValues, onChange]
  )

  return { values: filterValues, initialValues, onChange, onClearAll }
}
