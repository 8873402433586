import { rem } from 'polished'
import React, { useRef } from 'react'
import { Flex } from 'components/Layout'
import { FILTER_VIEW_COMPONENT_NAMES } from 'constants/common'
import { CheckboxList } from './CheckboxList'
import { CHECKBOX_STATUSES } from './constants'
import { type GeneralTreeNode } from './GeneralTree'
import { TreeSelect } from './TreeSelect'

export type CollapsibleTreeItem = {
  name: string
  id: number
  parentId?: number
  children: CollapsibleTreeItem[]
}

type CategoryTreeFilterProps = {
  value: number[]
  options: CollapsibleTreeItem[]
  onChange: (values: number[]) => void
}

const buildTreeNodes = (
  treeSelect: TreeSelect,
  item: CollapsibleTreeItem,
  parentNode?: GeneralTreeNode
) => {
  const parent = treeSelect.tree.insert(
    parentNode ?? item.parentId ?? 0,
    item.id,
    {
      label: item.name,
      status: CHECKBOX_STATUSES.UNCHECKED,
    }
  )
  if (item.children?.length) {
    item.children.map((child) => buildTreeNodes(treeSelect, child, parent))
  }
}

// Function for building tree based on category items
const buildTree = (options: CollapsibleTreeItem[], value: number[]) => {
  // Tree must have one single root node - as we have categories on the same 1 lvl we need to create root with additional status Innactive so we can simply check if its root node
  const tree = new TreeSelect(0, {
    label: 'root',
    status: CHECKBOX_STATUSES.INACTIVE,
  })
  options.forEach((option) => buildTreeNodes(tree, option))
  // update nodes status based on values from filter - this has to be done after whole tree is built because parent status can affect child status and child status can affect parent status
  tree.setDefaultNodeStatuses(tree, value)
  return tree
}

export const CollapsibleTreeSelect = ({
  value = [],
  options = [],
  onChange,
}: CategoryTreeFilterProps) => {
  const treeSelect = useRef(null)
  if (treeSelect.current === null) {
    treeSelect.current = buildTree(options, value)
  }
  return (
    <Flex
      flexDirection="column"
      width={{ MOBILE: '100%', TABLET: 'max-content' }}
    >
      <Flex flexDirection="column" maxHeight={{ TABLET: rem(500) }}>
        <CheckboxList
          treeSelect={treeSelect?.current}
          onChange={onChange}
          activeNodeKeys={value}
          itemsToRender={treeSelect?.current?.tree.root.children}
        />
      </Flex>
    </Flex>
  )
}

CollapsibleTreeSelect.identification =
  FILTER_VIEW_COMPONENT_NAMES.COLLAPSIBLE_TREE_SELECT
