import { rem } from 'polished'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { CHECKBOX_SIZE, CheckboxGroup } from 'components/Checkbox'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { FILTER_VIEW_COMPONENT_NAMES } from 'constants/common'
import { SPACE } from 'Theme'
import { useMedia } from 'useMedia'

const StyledText = styled(Text)`
  text-decoration: underline;
`

const GRID_COLUMN_GAP = SPACE.PX_40

const getOptionValue = ({ label, value }) => value || label

const computeCollapsedOptions = (collapseLimit, options) => {
  const hasChildren = options.some((option) => Boolean(option?.children))
  if (!hasChildren) {
    return options.slice(0, collapseLimit)
  }
  const { result: computedOptions } = options.reduce(
    (acc, option) => {
      if (acc.counter >= collapseLimit) {
        return acc
      }
      const { counter, result } = acc
      if (option.children?.length) {
        const leftSpaces = collapseLimit - counter
        if (leftSpaces >= option.children.length) {
          return {
            counter: counter + option.children.length,
            result: [...result, option],
          }
        }
        return {
          counter: counter + leftSpaces,
          result: [
            ...result,
            {
              ...option,
              children: option.children.slice(0, leftSpaces),
            },
          ],
        }
      }
      return {
        counter: counter + 1,
        result: [...result, option],
      }
    },
    {
      result: [],
      counter: 0,
    }
  )
  return computedOptions
}

const getOptionsCount = (options) =>
  options.reduce(
    (counter, option) =>
      counter + (option.children ? option.children.length : 1),
    0
  )

export const TagFilter = ({
  value,
  options = [],
  noDataLabel,
  columns,
  onChange,
  isCollapsible = false,
  collapseLimit = 8,
  isDisabled = false,
}) => {
  const media = useMedia()
  const [isCollapsed, setIsCollapsed] = useState(true)
  const isShowMoreVisible =
    isCollapsible && getOptionsCount(options) > collapseLimit
  const filterOptions =
    isCollapsed && isShowMoreVisible
      ? computeCollapsedOptions(collapseLimit, options)
      : options

  return (
    <Flex
      flexDirection="column"
      minWidth={{ TABLET: rem(200) }}
      width={{ MOBILE: '100%', TABLET: 'max-content' }}
    >
      <Flex
        flexDirection="column"
        p={{ MOBILE: 0, TABLET: SPACE.PX_20 }}
        maxHeight={{ TABLET: rem(350) }}
      >
        {options.length === 0 ? (
          <Flex justifyContent="center">{noDataLabel}</Flex>
        ) : (
          <>
            <CheckboxGroup
              gridColumnGap={GRID_COLUMN_GAP}
              gridRowGap={{ MOBILE: SPACE.PX_10, TABLET: 0 }}
              gridTemplateColumns={`repeat(${columns}, 1fr)`}
              size={media.MOBILE ? CHECKBOX_SIZE.L : CHECKBOX_SIZE.M}
            >
              {filterOptions.map((tag) => {
                const { id, label, children = [] } = tag
                const optionValue = getOptionValue(tag)
                if (children.length) {
                  const checkedChildrenCount = children.filter((child) =>
                    value.includes(child.label)
                  ).length
                  return (
                    <CheckboxGroup.Parent
                      key={id}
                      name={label}
                      parentValue={optionValue}
                      onChange={(val) => {
                        onChange([...new Set(val)])
                      }}
                      value={value}
                      options={children}
                      checkedChildrenCount={checkedChildrenCount}
                      childrenCount={children.length}
                      gridTemplateColumns="1fr 1fr"
                      gridColumnGap={GRID_COLUMN_GAP}
                      mt={SPACE.PX_5}
                      mb={SPACE.PX_10}
                      mr={SPACE.PX_12}
                    >
                      {children.map((childrenOption) => {
                        const { id: childId, label: childLabel } =
                          childrenOption
                        const childrenOptionValue =
                          getOptionValue(childrenOption)
                        const isChildrenChecked =
                          value.includes(childrenOptionValue)
                        return (
                          <CheckboxGroup.Item
                            key={childId}
                            name={childLabel}
                            value={childrenOptionValue}
                            isChecked={isChildrenChecked}
                          >
                            {childLabel}
                          </CheckboxGroup.Item>
                        )
                      })}
                    </CheckboxGroup.Parent>
                  )
                }
                const isChecked = value.includes(optionValue)
                return (
                  <CheckboxGroup.Item
                    onChange={() => {
                      if (isChecked) {
                        onChange(
                          value.filter((option) => optionValue !== option)
                        )
                      } else {
                        onChange([...value, optionValue])
                      }
                    }}
                    key={id}
                    name={label}
                    value={optionValue}
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                  >
                    {label}
                  </CheckboxGroup.Item>
                )
              })}
            </CheckboxGroup>
            {isShowMoreVisible && (
              <StyledText
                mt={SPACE.PX_12}
                onClick={() => {
                  setIsCollapsed((prevState) => !prevState)
                }}
              >
                Show {isCollapsed ? 'more' : 'less'}
              </StyledText>
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}

TagFilter.identification = FILTER_VIEW_COMPONENT_NAMES.TAG

TagFilter.defaultProps = {
  noDataLabel: 'No options',
  columns: 1,
  value: [],
  isDisabled: false,
}

TagFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ).isRequired,
  noDataLabel: PropTypes.string,
  columns: PropTypes.number,
  isCollapsible: PropTypes.bool,
  collapseLimit: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
}
