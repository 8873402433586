import React from 'react'
import { CheckboxGroup } from 'components/Checkbox'
import { SPACE } from 'Theme'
import { CheckboxNode } from './CheckboxNode'
import { type TreeSelect } from './TreeSelect'
import { type TreeNode } from './types'

type CheckboxListProps = {
  itemsToRender: TreeNode[]
  treeSelect: TreeSelect
  indentLevel?: number
  onChange: (values: number[]) => void
  activeNodeKeys: number[]
}

export const CheckboxList: React.FC<React.PWC<CheckboxListProps>> = ({
  itemsToRender = [],
  treeSelect,
  indentLevel = 0,
  onChange,
  activeNodeKeys = [],
}) => {
  const getChildNodes = (children: TreeNode[]) => (
    <CheckboxList
      itemsToRender={children}
      treeSelect={treeSelect}
      indentLevel={indentLevel + 1}
      onChange={onChange}
      activeNodeKeys={activeNodeKeys}
    />
  )

  return (
    <CheckboxGroup
      ml={indentLevel > 0 && SPACE.PX_20}
      p={{ MOBILE: 0, TABLET: indentLevel === 0 ? SPACE.PX_20 : 0 }}
      gridRowGap={{ MOBILE: SPACE.PX_10, TABLET: 0 }}
      pt={{
        MOBILE: indentLevel > 0 && SPACE.PX_10,
        TABLET: indentLevel === 0 ? SPACE.PX_20 : 0,
      }}
    >
      {itemsToRender.map((item) => (
        <CheckboxNode
          node={item}
          onChange={onChange}
          treeSelect={treeSelect}
          activeNodeKeys={activeNodeKeys}
          key={item.id}
          getChildNodes={getChildNodes}
        />
      ))}
    </CheckboxGroup>
  )
}
