import { rem } from 'polished'
import React from 'react'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import Icons from 'components/Icon'
import { Text } from 'components/Typography'
import {
  FONT_SIZE,
  RADIUS,
  SPACE,
  COLOR,
  BORDER_WIDTH,
  GRADIENT,
  type FONT_WEIGHT,
  getBorderColor,
} from 'Theme'

export const CHECKBOX_SIZE = {
  S: rem(14),
  M: rem(16),
  L: rem(20),
}

const CHECKMARK_SIZE = {
  [CHECKBOX_SIZE.S]: '10px',
  [CHECKBOX_SIZE.M]: '10px',
  [CHECKBOX_SIZE.L]: '14px',
}

const CHECKBOX_BACKGROUND = `linear-gradient(${GRADIENT.GRADIENT_2}), ${COLOR.WHITE};`

type CheckBoxWrapperProps = {
  borderColor: string
  isChecked?: boolean
  isIndeterminant?: boolean
  isDisabled?: boolean
  size: (typeof CHECKBOX_SIZE)[keyof typeof CHECKBOX_SIZE]
}

const CheckBoxWrapper = styled('div')<CheckBoxWrapperProps>`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: ${BORDER_WIDTH.PX_1} solid ${({ borderColor }) => borderColor};
  box-sizing: border-box;
  border-radius: ${RADIUS.PX_3};
  background: ${({ isChecked, isIndeterminant, borderColor }) =>
    isChecked || isIndeterminant ? borderColor : CHECKBOX_BACKGROUND};
  ${({ isDisabled, isChecked, isIndeterminant }) =>
    isDisabled &&
    (isChecked || isIndeterminant
      ? `opacity: 0.5;`
      : `background: ${COLOR.GRAYSCALE_5};`)}
  ${({ size }) =>
    size &&
    `
    height: ${size};
    width: ${size};
  `}
`

type LabelProps = {
  isChecked?: boolean
  isIndeterminant?: boolean
  isDisabled?: boolean
  name: string
}

const Label = styled('label')<LabelProps>`
  display: inline-flex;
  align-items: center;
  margin: ${SPACE.PX_5} 0;
  width: max-content;
  input {
    display: none;
  }
  &:hover {
    ${({ isDisabled }) => !isDisabled && `cursor: pointer;`}
    ${CheckBoxWrapper} {
      ${({ isChecked, isIndeterminant, isDisabled }) =>
        !isChecked &&
        !isIndeterminant &&
        !isDisabled &&
        `background: linear-gradient(${GRADIENT.GRADIENT_2_HOVER}), ${COLOR.WHITE};`}
    }
  }
`

const getFontSize = (size) => {
  if (size === CHECKBOX_SIZE.L) {
    return FONT_SIZE.PX_16
  }
  if (size === CHECKBOX_SIZE.S) {
    return FONT_SIZE.PX_12
  }
  return FONT_SIZE.PX_14
}

const getDataTestClass = ({ isChecked, isIndeterminant }): string => {
  if (isChecked) {
    return DATA_TEST_CLASS.CHECKBOX_CHECKED
  }
  if (isIndeterminant) {
    return DATA_TEST_CLASS.CHECKBOX_INDETERMINATE
  }
  return DATA_TEST_CLASS.CHECKBOX_UNCHECKED
}
export interface CheckboxItemProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  isIndeterminant?: boolean
  isError?: boolean
  isChecked?: boolean
  isDisabled?: boolean
  isWarning?: boolean
  size?: (typeof CHECKBOX_SIZE)[keyof typeof CHECKBOX_SIZE]
  fontWeight?: (typeof FONT_WEIGHT)[keyof typeof FONT_WEIGHT]
  hint?: string
}

export const CheckboxItem = React.forwardRef<
  HTMLInputElement,
  CheckboxItemProps
>(
  (
    {
      name,
      children,
      onChange,
      isChecked = false,
      isIndeterminant = false,
      isDisabled = false,
      isError = false,
      value,
      size = CHECKBOX_SIZE.M,
      isWarning,
      fontWeight,
      ...props
    },
    ref
  ) => {
    const fontSize = getFontSize(size)
    return (
      <Label
        isDisabled={isDisabled}
        isChecked={isChecked}
        isIndeterminant={isIndeterminant}
        name={name}
      >
        <input
          type="checkbox"
          name={name}
          value={value}
          checked={isChecked}
          disabled={isDisabled}
          onChange={onChange}
          ref={ref}
          {...props}
        />
        <CheckBoxWrapper
          data-test-name={name}
          size={size}
          isDisabled={isDisabled}
          isChecked={isChecked}
          isIndeterminant={isIndeterminant}
          data-test-id={DATA_TEST_CLASS.CHECKBOX}
          data-test-status={getDataTestClass({ isChecked, isIndeterminant })}
          borderColor={getBorderColor({
            isFocusedOrChecked: isChecked || isIndeterminant,
            isError,
            isWarning,
          })}
        >
          {isChecked && (
            <Icons.Checkmark size={CHECKMARK_SIZE[size]} color={COLOR.WHITE} />
          )}
          {isIndeterminant && (
            <Icons.Minus size={CHECKMARK_SIZE[size]} color={COLOR.WHITE} />
          )}
        </CheckBoxWrapper>
        <Text
          pl={SPACE.PX_10}
          color={isDisabled ? COLOR.GRAYSCALE_3 : COLOR.GRAYSCALE_1}
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          {children}
        </Text>
      </Label>
    )
  }
)
