import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import { Button, BUTTON_VARIANT } from 'components/Button'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Flex } from 'components/Layout'
import { COLOR_INTENT, SPACE } from 'Theme'

const StyledButton = styled(Button)`
  ${({ isOpen }) =>
    isOpen &&
    css`
      border-color: ${COLOR_INTENT.FILTER_BUTTON.OPEN_BORDER};
      &:hover {
        border-color: ${COLOR_INTENT.FILTER_BUTTON.OPEN_BORDER};
      }
    `}
  ${({ isOpen, isActive }) =>
    isActive &&
    !isOpen &&
    css`
      border-color: ${COLOR_INTENT.FILTER_BUTTON.ACTIVE_BORDER};
    `}
`

export const FilterButton = ({
  children,
  isActive,
  isOpen,
  onClick,
  ...rest
}) => (
  <StyledButton
    variant={BUTTON_VARIANT.OUTLINE}
    isActive={isActive}
    isOpen={isOpen}
    onClick={onClick}
    {...rest}
  >
    <Flex alignItems="center" justifyContent="space-between">
      {children}
      <Flex pl={SPACE.PX_10}>
        <Icon.CaretDown
          size={ICON_SIZE.PX_20}
          color={COLOR_INTENT.SELECT.CARET}
        />
      </Flex>
    </Flex>
  </StyledButton>
)

FilterButton.propTypes = {
  children: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}
