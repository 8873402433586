import React, { type ReactElement } from 'react'
import { Hint } from 'components/Input'
import { Flex, Grid } from 'components/Layout'
import { type CustomGridProps } from 'components/Layout/Grid'
import { Text } from 'components/Typography'
import { COLOR_INTENT, SPACE } from 'Theme'
import { type CHECKBOX_SIZE, type CheckboxItemProps } from './CheckboxItem'
import { type CheckboxParentProps } from './CheckboxParent'

export interface CheckboxGroupProps extends CustomGridProps {
  children:
    | ReactElement<CheckboxItemProps | CheckboxParentProps>
    | ReactElement<CheckboxItemProps | CheckboxParentProps>[]
  size?: (typeof CHECKBOX_SIZE)[keyof typeof CHECKBOX_SIZE]
  label?: string
  hint?: string
  isError?: boolean
}

interface ExtendedCheckboxGroup extends React.FC<CheckboxGroupProps> {
  Item?: React.ForwardRefExoticComponent<CheckboxItemProps>
  Parent?: React.FC<React.PWC<CheckboxParentProps>>
}

export const CheckboxGroup: ExtendedCheckboxGroup = ({
  children,
  size,
  label,
  hint,
  isError,
  ...rest
}) => (
  <Flex flexDirection="column" overflowY="auto">
    {label && <Text color={COLOR_INTENT.CHECKBOX.TEXT}>{label}</Text>}
    {hint && (
      <Hint
        isError={isError}
        marginBottom={SPACE.PX_5}
        {...(isError ? {} : { color: COLOR_INTENT.CHECKBOX.HINT })}
      >
        {hint}
      </Hint>
    )}
    <Grid {...rest}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { size })
      )}
    </Grid>
  </Flex>
)
