import { difference, find, get, lowerCase } from 'lodash'
import {
  type FilterTypes,
  type FilterConfiguration,
  type GoogleAnalyticsContextInterface,
} from 'providers/googleAnalytics/types'
import { mapDataToFilterEvent } from '../helpers'

interface Props
  extends Pick<GoogleAnalyticsContextInterface, 'executeDataToDataLayer'> {
  isEnabled: boolean
  newValues: Array<string>
  previousValues: Array<string> | undefined
  options: FilterConfiguration['viewComponentProps']['options']
  type: FilterTypes
}

export const executePrimitiveSingleValueFilterEvent = ({
  isEnabled,
  newValues,
  previousValues,
  options,
  type,
  executeDataToDataLayer,
}: Props) => {
  if (!isEnabled) {
    return
  }

  const updatedDataValue = difference(newValues, previousValues)[0]

  if (updatedDataValue !== undefined) {
    const updatedLabel = get(
      find(options, {
        value: updatedDataValue,
      }),
      'label',
      updatedDataValue
    )

    executeDataToDataLayer(
      mapDataToFilterEvent({
        type,
        action: lowerCase(updatedLabel),
      })
    )
  }
}
