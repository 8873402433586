import { difference, find, get, lowerCase } from 'lodash'
import {
  type FilterValues,
  type FilterConfiguration,
  GA4_FILTER_TYPES,
  type GoogleAnalyticsContextInterface,
} from 'providers/googleAnalytics/types'
import { mapDataToFilterEvent } from '../helpers'

interface Props
  extends Pick<GoogleAnalyticsContextInterface, 'executeDataToDataLayer'> {
  isEnabled: boolean
  newValues: FilterValues['regions']
  previousValues: FilterValues['regions']
  options: FilterConfiguration['viewComponentProps']['options']
}

export const executeRegionFilterEvent = ({
  isEnabled,
  newValues,
  previousValues,
  options,
  executeDataToDataLayer,
}: Props) => {
  if (!isEnabled) {
    return
  }

  const updatedDataLabels = difference(newValues, previousValues)

  if (!updatedDataLabels.length) {
    return
  }

  const childParentPairs = updatedDataLabels.reduce((acc, childLabel) => {
    const data = find(options, {
      children: [{ label: childLabel }],
    })
    return {
      ...acc,
      [childLabel]: get(data, 'label'),
    }
  }, {})

  updatedDataLabels.forEach((updatedLabel) => {
    const parentLabel = get(childParentPairs, updatedLabel)

    executeDataToDataLayer(
      mapDataToFilterEvent({
        type: GA4_FILTER_TYPES.REGIONS,
        action: parentLabel
          ? `${lowerCase(parentLabel)} / ${lowerCase(updatedLabel)}`
          : lowerCase(updatedLabel),
      })
    )
  })
}
