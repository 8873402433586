import React, { useEffect, useRef } from 'react'
import { Flex, Grid } from 'components/Layout'
import { type CustomGridProps } from 'components/Layout/Grid'
import { FONT_WEIGHT } from 'Theme'
import { CheckboxItem, type CheckboxItemProps } from './CheckboxItem'

type Option = {
  label?: string
  title?: string
}

export interface CheckboxParentProps
  extends Omit<CustomGridProps, 'size'>,
    Pick<CheckboxItemProps, 'name' | 'size'> {
  childrenCount: number
  checkedChildrenCount: number
  parentValue?: CheckboxItemProps['value']
  options: Option[]
  value?: CheckboxItemProps['value'][]
  onChange: (value: CheckboxItemProps['value'][]) => void
}

export const CheckboxParent: React.FC<React.PWC<CheckboxParentProps>> = ({
  children,
  size,
  name,
  parentValue,
  onChange,
  value = [],
  options,
  checkedChildrenCount,
  childrenCount,
  ...rest
}) => {
  const parentCheckboxRef = useRef(null)
  const isChecked =
    checkedChildrenCount === childrenCount && checkedChildrenCount > 0
  const isIndeterminant = checkedChildrenCount > 0 && !isChecked

  useEffect(() => {
    parentCheckboxRef.current.indeterminate = isIndeterminant
  }, [isIndeterminant])

  return (
    <Flex
      flexDirection="column"
      overflowY="auto"
      width={{ TABLET: 'max-content' }}
    >
      <CheckboxItem
        name={name}
        value={parentValue}
        isChecked={isChecked}
        onChange={() => {
          if (checkedChildrenCount === childrenCount) {
            onChange(
              value.filter(
                (title) => !options.some((child) => title === child.label)
              )
            )
          } else {
            onChange([...value, ...options.map((child) => child.label)])
          }
        }}
        isIndeterminant={isIndeterminant}
        fontWeight={FONT_WEIGHT.SEMI_BOLD}
        ref={parentCheckboxRef}
        size={size}
      >
        {name}
      </CheckboxItem>
      <Grid {...rest}>
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return null
          }

          return React.cloneElement<Partial<CheckboxItemProps>>(child, {
            size,
            onChange: ({ currentTarget: { checked, value: childValue } }) => {
              if (!checked) {
                onChange(value.filter((title) => childValue !== title))
              } else {
                onChange([...value, childValue])
              }
            },
          })
        })}
      </Grid>
    </Flex>
  )
}
