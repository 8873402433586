import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { FONT_SIZE, FONT_WEIGHT, SPACE } from 'Theme'

const CustomText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
  width: max-content;
`

export const ClearAll = ({ onClick }) => (
  <Flex
    p={SPACE.PX_10}
    mx={{ MOBILE: SPACE.PX_15, TABLET: 0 }}
    justifyContent="center"
    alignItems="center"
    onClick={onClick}
  >
    <CustomText fontSize={FONT_SIZE.PX_13} fontWeight={FONT_WEIGHT.MEDIUM}>
      Clear all
    </CustomText>
  </Flex>
)

ClearAll.propTypes = {
  onClick: PropTypes.func.isRequired,
}
