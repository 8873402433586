import { compact, find, flatMap, get, isEmpty, pick } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import {
  COMPOSED_FILTERS_CONFIGURATION_NAMES,
  FILTER_VIEW_COMPONENT_NAMES,
} from 'constants/common'
import { useCurrency } from 'providers/currency'
import { useUserAuth } from 'providers/userAuth'
import {
  formatAvailabilityFilterLabel,
  formatPriceFilterLabel,
} from 'utils/formatters'
import { AppliedFiltersWrapper } from './AppliedFiltersWrapper'
import { AppliedFilterTag } from './AppliedFilterTag'

// We need this function because every filter has options that have title same as value apart from the Session Length filter
const getTagFilterLabel = ({ value, key, options }) => {
  if (
    [
      COMPOSED_FILTERS_CONFIGURATION_NAMES.COLLECTIONS,
      COMPOSED_FILTERS_CONFIGURATION_NAMES.AVAILABILITY,
      COMPOSED_FILTERS_CONFIGURATION_NAMES.SESSION_LENGTH,
      COMPOSED_FILTERS_CONFIGURATION_NAMES.CATEGORIES,
    ].includes(key)
  ) {
    return get(find(options, { value }), 'label')
  }
  return value
}

const getCollapsibleTreeSelectLabel = ({ value, options = [] }) => {
  const node = options.find((option) => option.id === value)
  if (node) {
    return node.name
  }
  const children = compact(flatMap(options, 'children'))
  if (children.length > 0) {
    return getCollapsibleTreeSelectLabel({
      options: children,
      value,
    })
  }

  return null
}

export const AppliedFilters = ({ data, onClearAll, onChange }) => {
  const { convertDateToTimezone } = useUserAuth()
  const { currencyIsoCode } = useCurrency()
  return (
    <AppliedFiltersWrapper data={data} onClearAll={onClearAll}>
      {({ filterName, value, viewComponentName, viewComponentProps }) => {
        if (viewComponentName === FILTER_VIEW_COMPONENT_NAMES.AVAILABILITY) {
          const { availabilityFrom, availabilityTo } = pick(value, [
            'availabilityFrom',
            'availabilityTo',
          ])
          if (availabilityFrom === undefined || availabilityTo === undefined) {
            return null
          }
          return (
            <AppliedFilterTag
              key={`${filterName}_${value.availabilityFrom}-${value.availabilityTo}`}
              onClick={() =>
                onChange({
                  updatedValues: {
                    availability: {
                      availabilityFrom: undefined,
                      availabilityTo: undefined,
                    },
                  },
                })
              }
            >
              {formatAvailabilityFilterLabel(
                convertDateToTimezone(availabilityFrom),
                convertDateToTimezone(availabilityTo)
              )}
            </AppliedFilterTag>
          )
        }

        if (viewComponentName === FILTER_VIEW_COMPONENT_NAMES.TAG) {
          if (isEmpty(value)) {
            return null
          }
          return (
            <React.Fragment key={filterName}>
              {value.map((optionValue) => (
                <AppliedFilterTag
                  key={`${filterName}_${optionValue}`}
                  onClick={() =>
                    onChange({
                      updatedValues: {
                        [filterName]: value.filter(
                          (option) => option !== optionValue
                        ),
                      },
                    })
                  }
                >
                  {getTagFilterLabel({
                    value: optionValue,
                    key: filterName,
                    options: viewComponentProps.options,
                  })}
                </AppliedFilterTag>
              ))}
            </React.Fragment>
          )
        }

        if (
          viewComponentName ===
          FILTER_VIEW_COMPONENT_NAMES.COLLAPSIBLE_TREE_SELECT
        ) {
          if (isEmpty(value)) {
            return null
          }
          return (
            <React.Fragment key={filterName}>
              {value.map((optionValue) => (
                <AppliedFilterTag
                  key={`${filterName}_${optionValue}`}
                  onClick={() =>
                    onChange({
                      updatedValues: {
                        [filterName]: value.filter(
                          (option) => option !== optionValue
                        ),
                      },
                    })
                  }
                >
                  {getCollapsibleTreeSelectLabel({
                    value: optionValue,
                    options: viewComponentProps.options,
                  })}
                </AppliedFilterTag>
              ))}
            </React.Fragment>
          )
        }

        if (viewComponentName === FILTER_VIEW_COMPONENT_NAMES.PRICE) {
          const maxPrice = get(viewComponentProps, 'maxPrice')
          const priceFrom = get(value, 'priceFrom', 0)
          const priceTo = get(value, 'priceTo', maxPrice)
          if (priceFrom === 0 && priceTo === maxPrice) {
            return null
          }
          const setPriceFrom = get(viewComponentProps, 'setPriceFrom')
          const setPriceTo = get(viewComponentProps, 'setPriceTo')
          return (
            <AppliedFilterTag
              key={`${filterName}_${priceFrom}-${priceTo}`}
              onClick={() => {
                onChange({
                  updatedValues: { price: { priceFrom: 0, priceTo: maxPrice } },
                })
                setPriceFrom(0)
                setPriceTo(maxPrice)
              }}
            >
              {formatPriceFilterLabel({
                value: {
                  priceFrom,
                  priceTo,
                },
                currencyIsoCode,
                maxPrice,
              })}
            </AppliedFilterTag>
          )
        }

        return null
      }}
    </AppliedFiltersWrapper>
  )
}

AppliedFilters.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      filterName: PropTypes.oneOf(
        Object.values(COMPOSED_FILTERS_CONFIGURATION_NAMES)
      ).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.shape({
          availabilityFrom: PropTypes.string,
          availabilityTo: PropTypes.string,
        }),
        PropTypes.shape({
          priceFrom: PropTypes.number,
          priceTo: PropTypes.number,
        }),
      ]).isRequired,
      viewComponentName: PropTypes.oneOf(
        Object.values(FILTER_VIEW_COMPONENT_NAMES)
      ).isRequired,
      maxPrice: PropTypes.number,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
}
